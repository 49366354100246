import React from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { DateTimePicker } from "@material-ui/pickers";
import { Button } from "@material-ui/core";
import { useStyles } from "./styles.js";
import moment from "moment-timezone";
import * as Yup from "yup";
import TicketService from "../../../services/TicketService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { generateUUID } from "../../Rate";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSatelliteDish } from "@fortawesome/pro-duotone-svg-icons";
import CancelIcon from "@material-ui/icons/Cancel";
import * as c from "../../../constants";
import useDeviceTimezone from "../../../hooks/useDeviceTimezone/index.js";

const ticketService = new TicketService(apiClient);
export default function NegotiateTicketForm({ entityID, onSubmit, onClose, setModifiedEntryTime }) {
  const classes = useStyles();
  const { convertToDeviceTimezone } = useDeviceTimezone(entityID);

  const NegotiateTicketSchema = Yup.object().shape({
    entryTime: Yup.date()
      .max(moment().local(), "Entry time cannot be later than current time")
      .required("Entry time is required"),
  });

  const nowLocal = moment()
    .seconds(0)
    .milliseconds(0)
    .local();
  const nowDevice = convertToDeviceTimezone(nowLocal);

  const { submitForm, setFieldValue, errors, values, isSubmitting } = useFormik(
    {
      initialValues: {
        entryTime: nowDevice,
      },
      onSubmit: handleSubmit,
      validationSchema: NegotiateTicketSchema,
    }
  );

  const enqueueSnackbar = useEnqueueSnackbar();

  async function handleSubmit(submitValues) {
    try {
      const response = await ticketService.negotiateTicket({
        entityID,
        issuedDate: submitValues.entryTime,
        lostTicket: true,
        ticketID: generateUUID(),
        status: c.ACTIVITY_TYPES.Enter, // it does not matter what this value is since it is a lost ticket, and the status will not be recorded in this command
        // the command handler when creating a ticket disregards creating an activity for lost ticket, so that we may handle that on our own
        // I think that this is probably tech debt that we'll want to refactor in the future since those actions no longer need to be separated
      });
      if (!response || response.status !== 200) {
        throw "Invalid response";
      }
      onSubmit(response.data);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to create negotiated ticket", {
        variant: "error",
        tag: "FailedToCreateNegotiatedTicket",
      });
    }
  }

  return (
    <div
      data-testid="negotiated-ticket-form"
      className={clsx(classes.root, "negotiated-ticket-form")}
    >
      <DateTimePicker
        name="entryTime"
        className={clsx(classes.entryTime, "negotiated-ticket-entry-time")}
        label="Entry Time"
        value={values.entryTime}
        onChange={(date) => {
          if (date) {
            setModifiedEntryTime(date);
            setFieldValue("entryTime", date);
          }
        }}
        error={errors && errors.entryTime ? true : false}
        helperText={errors && errors.entryTime}
        format="MM/DD/YYYY h:mm A zz"
        inputProps={{ "aria-label": "Negotiated Entry Time" }}
      />

      <Button
        startIcon={<CancelIcon />}
        className={clsx(classes.submitBtn, "negotiated-ticket-cancel-button")}
        variant="contained"
        color="default"
        onClick={onClose}
        disabled={isSubmitting}
        style={{ float: "right" }}
      >
        Cancel
      </Button>
      <Button
        startIcon={<FontAwesomeIcon icon={faSatelliteDish} />}
        className={clsx(classes.submitBtn, "negotiated-ticket-submit-button")}
        variant="contained"
        color="primary"
        onClick={submitForm}
        disabled={isSubmitting}
        style={{ float: "right", marginRight: 10 }}
      >
        Send
      </Button>
    </div>
  );
}

NegotiateTicketForm.defaultProps = {
  onSubmit: () => {},
  onClose: () => {
    console.log("NOT IMPLEMENTED");
  },
  setModifiedEntryTime: () => {}
};

NegotiateTicketForm.propTypes = {
  onSubmit: PropTypes.func,
  entityID: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  setModifiedEntryTime: PropTypes.func
};
